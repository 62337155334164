<template>
  <el-container class="home_box">
    <topHome @openUserInfoDialo="userInfoVisible = true" @changeMenu="changeMenu"
      @checkLeftMenu="isCollapse = !isCollapse" v-if="!isFullScreen" @log-out="
  isError = true;
socket.close();
      "></topHome>
    <el-container v-if="!isMenuRoute">
      <!-- 不是开发者或者小助手那种头部nav是变化的 -->
      <template v-if="!isBigScreen">
        <leftHome :isCollapse="isCollapse" v-if="
          (theme == 1 || theme == 2) &&
          leftMenuList.length > 0 &&
          !leftMenuList[0].is_btn
        "></leftHome>
        <el-main>
          <template v-if="activeTabList.length > 0">
            <homeTabs ref="tab"></homeTabs>
          </template>
          <div class="main_box" v-else>
            <router-view />
          </div>
        </el-main>
      </template>
      <template v-else>
        <iframe :src="three3DUrl" width="100%" height="100%" frameborder="0" scrolling="no" v-if="three3DUrl"></iframe>
        <router-view v-else />
      </template>
    </el-container>
    <el-container v-else>
      <!-- 是开发者或小助手头部变化 -->
      <div class="aside-out-view" v-if="(theme == 1 || theme == 2) && leftMenuList.length > 0">
        <el-aside>
          <el-menu :default-active="activeIndex" collapse-transition active-text-color="#409EFF" unique-opened
            @select="handSelect">
            <template v-for="item in leftMenuList">
              <el-submenu :index="item.id" v-if="item.children.length > 0" :key="item.id" class="el-menu-item_box">
                <template slot="title">{{ item.title }}</template>
                <template v-for="data in item.children">
                  <el-submenu :index="data.id" v-if="data.children.length > 0" :key="data.id" class="el-menu-item_box">
                    <templatey slot="title">{{ data.title }}</templatey>
                    <el-menu-item v-for="li in data.children" :key="li.id" :index="li.id">{{ li.title }}</el-menu-item>el-menu-item>
                  </el-submenu>
                  <el-menu-item :index="data.id" :key="data.id" v-else class="my_el-menu-item">
                    <span slot="title">{{ data.title }}</span>
                  </el-menu-item>
                </template>
              </el-submenu>
              <el-menu-item :index="item.id" :key="item.id" v-else class="my_el-menu-item">
                <span slot="title">{{ item.title }}</span>
              </el-menu-item>
            </template>
          </el-menu>
        </el-aside>
      </div>
      <el-main class="my_main">
        <el-card class="box-card content_card" style="text-align: left">
          <template v-if="activeMenu">
            <router-view />
          </template>
          <div v-if="activeIndex">
            <div class="card_header">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item v-for="(item, index) in activeBreadcrumbList" :key="index">{{
                  item
                }}</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
            <div v-html="content" v-if="content" class="content_id"></div>
            <div class="empty_box" v-else>暂无数据</div>
          </div>
        </el-card>
      </el-main>
    </el-container>
    <!-- 用户基本信息 -->
    <userInfoDialog v-if="userInfoVisible" @closeUserInfoDialog="userInfoVisible = false"></userInfoDialog>
    <!-- 首次登录设置密码 -->
    <firstLoginDialog></firstLoginDialog>
  </el-container>
</template>
<script>
import { setTheme } from '../js/theme/theme.js'
import topHome from '../components/topHome/index.vue'
import userInfoDialog from '../components/userInfoDialog/index.vue'
import leftHome from '../components/leftHome/index.vue'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import firstLoginDialog from '../components/firstLoginDialog/index.vue'
import { getMaintenanceMainDetail } from '@/api/maintenanceCenter'
import homeTabs from '@/components/home-tabs.vue'
import utils from '@/utils/utils'
export default {
  name: 'Home',
  components: {
    topHome,
    userInfoDialog,
    firstLoginDialog,
    leftHome,
    homeTabs
  },
  data () {
    return {
      // 是否展示用户基本信息对话框
      userInfoVisible: false,
      // 左侧导航变化
      isCollapse: false,
      // webSocket
      socket: null,
      lockReconnet: false,
      isReconnet: false,
      isError: false,
      // 是否是运维中心及小助手
      isMenuRoute: false,
      // 主要内容
      content: '',
      isShowRight: false
    }
  },
  destroyed () {
    this.socket && this.socket.onclose()
  },
  created () {
    if (utils.getQueryString('access_toke')) {
      // console.log('首页请求-----');
      sessionStorage.setItem('token', utils.getQueryString('access_toke'))
      sessionStorage.setItem('refreshtoken', utils.getQueryString('refresh_token'))
      this.setScene(true)
      this.setDomainAddress(window.location.hostname)
      this.$store.dispatch('menuList/fngetGroupBigScreen')
      setTimeout(() => {
        this.$store.dispatch('menuList/requestMenus')
        this.$store.dispatch('requestPorkingList')
        this.$store.dispatch('requestFindList')
        this.$store.dispatch('requestUserInfo')
        this.$store.dispatch('requestAlarmCount')
      }, 200)
    }
    // 连接webSocket，用于接收后台实时报警信息推送
    this.initWebSocket()
  },

  mounted () {
    // 监听鼠标右键
    this.fnKeydown()
    // 获取 tabs_title_box的右击事件
    this.getElement()
    // 获取开发者或小助手页面
    if (this.activeIndex) {
      // this.isMenuRoute = true;
      // console.log('this.activeIndex----', this.activeIndex);
      this.fnGetMaintenanceMainDetail(this.activeIndex)
    }
    // console.log('--首页-mounted----', this.activeMenu);
    // console.log();
    if (this.activeMenu) {
      // 路由跳转
      this.$router.push(this.activeMenu)
    }
    if (sessionStorage.getItem('path')) {
      this.$router.push(sessionStorage.getItem('path'))
    }
    if (this.theme === 1 || this.theme === 3) {
      setTheme('default')
    } else {
      setTheme('light')
    }
  },
  computed: {
    ...mapGetters('menuList', ['theme']),
    // isFullScreen 是否是全屏（不展示浏览器部分）
    ...mapState(['isFullScreen']),
    ...mapState('menuList', [

      'activeMenu',
      'activeHearderMenu',
      'leftMenuList',
      'isBigScreen',
      'activeTabList',
      'activeIndex',
      'activeBreadcrumbList',
      'three3DUrl'
    ])
  },
  watch: {
    theme (newVal, oldV) {
      if (this.theme === 1 || this.theme === 3) {
        setTheme('default')
      } else {
        setTheme('light')
      }
    },
    // 监听右击展示的操作标签是否展示，若不展示则移除
    isShowRight (newV, oldV) {
      console.log('isShowRight-----', newV)
      if (!newV) {
        var boxmain = document.querySelector('#tabsBox')
        var menu = document.querySelectorAll('.menudiv')
        console.log(menu)
        if (menu.length > 0) {
          menu.forEach(item => {
            boxmain.removeChild(item)
          })
        }
      }
    },
    $route (to, from) {
      if (to.fullPath) {
        sessionStorage.setItem('path', to.fullPath)
      }
    },
    // 当前展示的路由
    activeMenu (newVal, old) {
      if (newVal) {
        if (newVal === '/home') {
          // 路由跳转
          this.$router.replace(newVal)
        } else {
          // 路由跳转
          this.$router.push(newVal)
          // 添加一个tab页
          this.addActiveTabList(newVal)
          // 获取按钮权限
          this.setCurrentBtn(newVal)
          this.setShowTab(newVal)
          // console.log(this.activeTabList);
          // console.log(this.isBigScreen);
          var that = this
          setTimeout(() => {
            // 重新渲染右击展示的页面
            that.getElement()
            // 设置 bigScreen;
            that.setIsBigScreen(newVal)
          }, 300)
        }
      }
    },
    activeIndex (newVal, old) {
      // console.log('newVal---activeIndex-', newVal);
      if (newVal) {
        this.fnGetMaintenanceMainDetail(newVal)
      }
    }
  },
  methods: {
    ...mapMutations('menuList', [
      'setActiveHearderMenu',
      'setActiveMenu',
      'addActiveTabList',
      'closeTab',
      'closeAllTab',
      'closeOtherTab',
      'setIsBigScreen',
      'setCurrentBtn',
      'setActiveIndex',
      'setActiveBreadcrumbList',
      'setShowTab',
      'setScene',
      'setDomainAddress'
    ]),
    changeMenu (e) {
      console.log('changeMenu---', e)
      this.isMenuRoute = e
    },
    fnKeydown () {
      var that = this
      document.onkeydown = function (e) {
        e = window.event || e
        if (e) {
          that.isShowRight = false
        }
      }
      document.onmousedown = function (e) {
        if (e.button === 0) {
          setTimeout(() => {
            that.isShowRight = false
          }, 500)
        } else if (e.button === 1) {
          that.isShowRight = false
        } else {
          that.isShowRight = false
        }
      }
    },
    // 获取 tabs_title_box的右击事件
    getElement () {
      var el = document.querySelectorAll('.tabs_title_box')
      // console.log('获取 tabs_title_box的右击事件----', el);
      if (el[0]) {
        var that = this
        el[0].oncontextmenu = function (e) {
          return false
        }
        el[0].addEventListener('mousedown', function (e) {
          if (e.button === 0) {
            // alert('你按下了鼠标左键!');
            that.isShowRight = false
          } else if (e.button === 1) {
            // alert('你按下了鼠标滚轮!');
            that.isShowRight = false
          } else {
            // alert('您按下了鼠标右键!')
            setTimeout(() => {
              that.fnRightclick(e)
            }, 50)
            console.log('mouse-e', e)
          }
        })
      }
    },
    // 右击事件
    fnRightclick (event) {
      var that = this
      // console.log('右击事件---', event);
      var screenX = event.clientX
      this.isShowRight = true
      // 1、获取整个tab组件的宽度
      var tabsBox = document.getElementById('tabsBox')
      // var t = tabsBox.getBoundingClientRect();
      // console.log('tabsBox-------', t.left);
      var maxWidth = tabsBox.getBoundingClientRect().width
      var leftWidth = tabsBox.getBoundingClientRect().left
      // console.log(maxWidth);
      // console.log(document.querySelector('.right_menu'));
      // var element = document.querySelector('.right_menu');
      // element.style.transform = "translateX(" + screenX + "px)";
      // var boxmain = document.querySelector('.tabs_box');
      var menudiv = document.createElement('ul')
      menudiv.className = 'menudiv'
      menudiv.style.backgroundColor = '#fff'
      menudiv.style.zIndex = 9999
      menudiv.style.border = '1px solid #ccc'
      menudiv.style.position = 'absolute'
      menudiv.style.top = '50px'
      if (screenX - leftWidth > maxWidth) {
        menudiv.style.right = 20 + 'px'
      } else {
        menudiv.style.left = screenX - leftWidth + 'px'
      }
      var lione = document.createElement('li')
      lione.innerText = '关闭当前'
      lione.style.padding = '10px'
      lione.style.boxSizing = 'border-box'
      lione.style.cursor = 'pointer'
      lione.style.listStyle = 'none'
      lione.onclick = function () {
        that.isShowRight = false
        that.closeTags()
      }
      var litwo = document.createElement('li')
      litwo.style.padding = '10px'
      litwo.style.boxSizing = 'border-box'
      litwo.style.cursor = 'pointer'
      litwo.style.listStyle = 'none'
      litwo.innerText = '关闭所有'
      litwo.onclick = function () {
        that.isShowRight = false
        that.closeAllTab()
      }
      var litthree = document.createElement('li')
      litthree.style.padding = '10px'
      litthree.style.boxSizing = 'border-box'
      litthree.style.cursor = 'pointer'
      litthree.style.listStyle = 'none'
      litthree.innerText = '关闭其它标签'
      litthree.onclick = function () {
        // console.log('关闭其它标签----');
        // that.fnClearOtherClick();
        that.isShowRight = false
        that.closeOtherTab()
      }
      menudiv.appendChild(lione)
      menudiv.appendChild(litwo)
      menudiv.appendChild(litthree)
      tabsBox.appendChild(menudiv)
    },
    // 关闭tab
    closeTags () {
      // console.log('closeTags----');
      var tag = this.activeMenu
      // console.log("==--=closeTag=--==", tag);
      // 删除Tag的时候始终从左至右，如果是最后一个那么久左移
      if (this.activeTabList.length > 1) {
        const index = this.activeTabList.findIndex((item) => item === tag)
        if (index === this.activeTabList.length - 1) {
          // 最后一个，展示前一个页面
          const path = this.activeTabList[index - 1]
          this.isShowRight = false
          this.setActiveMenu(path)
        } else {
          // 不是最后一个，展示后一个页面
          const path = this.activeTabList[index + 1]
          this.isShowRight = false
          this.setActiveMenu(path)
        }
      }
      console.log('tag', tag)
      this.closeTab(tag)
    },
    // 菜单切换触发
    handSelect (path) {
      this.setActiveIndex(path)
      this.setActiveMenu('')
    },
    async fnGetMaintenanceMainDetail (id) {
      const res = await getMaintenanceMainDetail({
        id: id
      })
      console.log('获取内容详情-----', res)
      if (res.Code === 200 && res.Data) {
        // this.breadcrumbList = res.Data.ParentTitle.split('/');
        this.setActiveBreadcrumbList(res.Data.ParentTitle.split('/'))
        this.content = res.Data.Content
      }
    },
    // 初始化weosocket
    initWebSocket () {
      const that = this
      if (typeof WebSocket === 'undefined') {
        this.$notify({
          title: '提示',
          message: '当前浏览器无法接收实时报警信息，请使用谷歌浏览器！',
          type: 'warning',
          position: 'bottom-right',
          duration: 0
        })
      } else {
        // 获取token
        const token = sessionStorage.getItem('token')
        // console.log(location.href.split('/')[0]);
        // console.log(location.href.split('/')[2]);
        var socketUrl
        if (location.href.split('/')[0] === 'https:') {
          // 有证书
          socketUrl = `wss://${location.href.split('/')[2]
            }/ws/ParkingAlarmWs?Token=${token}&SendOrReceive=2`
        } else {
          socketUrl = `ws://${location.href.split('/')[2]
            }/ws/ParkingAlarmWs?Token=${token}&SendOrReceive=2`
        }
        // socketUrl = `ws://tcity.lebopark.com/ws/ParkingAlarmWs?Token=${token}&SendOrReceive=2`;
        // 实例化socket
        this.socket = new WebSocket(socketUrl)
        // console.log(this.socket);
        // 监听socket打开
        this.socket.onopen = function () {
          // console.log('浏览器WebSocket已打开');
          this.isReconnet = false
          // var str = "{\"message_type\":1,\"group_id\":\"61b077003458c056d56af174\",\"message_content\":\"【工工aaa】出现【设备离线】情况\"}\u0000\u0000\u0000\u0000\u0000\u0000";
          // console.log(str.toString().replace(/\\u0000/g, ''));
          // var s = JSON.parse(str.substring(0, str.indexOf("}") + 1).replace(/\\u0000/g, ''));
        }
        // 监听socket消息接收
        this.socket.onmessage = function (msg) {
          // console.log('监听socket消息接收---', msg);
          // 转换为json对象
          // console.log(JSON.parse(msg.data.substring(0, msg.data.indexOf("}") + 1).replace(/\\u0000/g, '')));
          var data = JSON.parse(
            msg.data
              .substring(0, msg.data.indexOf('}') + 1)
              .replace(/\\u0000/g, '')
          )
          that.$notify({
            title: that.getWayName('MessageType', data.message_type),
            iconClass: that.GetMessageTypeIcon(data.message_type),
            message: data.message_content,
            position: 'bottom-right',
            duration: 5000
            // onClick: () => {
            //   that.$router.push({
            //     path: '/alarmManage/monitAlarmInfo'
            //   });
            // }
          })
          that.$store.dispatch('requestAlarmCount')
        }
        // 监听socket错误
        this.socket.onerror = function () {
          // console.log('服务器连接错误，无法接收实时告警信息');
          this.isError = true
          // that.reconnet(); // 重新连接
        }
        // 监听socket关闭
        this.socket.onclose = function () {
          // console.log('WebSocket已关闭');
          if (!this.isError) {
            that.reconnet() // 重新连接
          }
        }
      }
    },
    // 重新连接 WebSocket
    reconnet () {
      if (this.lockReconnet) {
        return false
      }
      this.isReconnet = true
      this.lockReconnet = true
      var that = this
      setTimeout(() => {
        that.initWebSocket()
        that.lockReconnet = false
      }, 2000)
    },
    //  告警信息字体图标类型颜色
    GetMessageTypeIcon (type) {
      switch (type) {
        case 1:
        case 2:
        case 3:
        case 4:
          return 'iconfont lebo-yichang red_icon'
        case 5:
          return 'iconfont lebo-yichang gray_icon'
        default:
          return 'iconfont lebo-yichang gray_icon'
      }
    }
  }
}
</script>
<style scoped lang="less">
@import url("~@/assets/css/home.less");
/deep/.content_id{
  img{
    display: block;
    width:unset !important;
    height: auto;
    object-fit: none;
    // margin: 0 auto;
  }
}
</style>
