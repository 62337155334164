<template>
  <!-- 首次登录设置密码 -->
  <lebo-dialog  append-to-body title="手机端" :isShow="isPhoneDialog" center @close="closeDialog()" class="phone_dialog"  footerSlot closeOnClickModal>
    <ul class="content_box" >
      <!-- <li class="content_item">
        <div class="title">商家优免端（H5）</div>
        <vue-qr class="qr_box" ref="qrRef" id="qrcode" :text="qrCodeUrl" :margin="0"></vue-qr>
        <div class="btn" @click="copyClick">复制地址</div>
        <div class="dowload_btn" @click="downQr">下载二维码</div>
      </li> -->
      <!-- <li class="content_item"></li>
      <li class="content_item"></li>
      <li class="content_item"></li>
      <li class="content_item"></li> -->
      <template v-for="item in phoneClientList">
        <li class="content_item" :key="item.value" :label="item.value" v-if="PhoneClient.includes(item.value)">
          <div class="title">{{ item.name }}</div>
          <!-- http://tcity.lebopark.com/uploadfiles/carpic/小乐停车(小程序).jpg -->
          <vue-qr class="qr_box" :ref="`qrRef${item.value}`" :text="setQrCode(item)" :margin="0" v-if="item.value != 6&&item.value !=7"></vue-qr>
          <img class="qr_box" :ref="`qrRef${item.value}`" :src="item.link" alt="" v-else />
          <div v-if="item.value == 4 || item.value == 5" class="btn" @click="downApk(item)">
            <i class="iconfont lebo-apk"></i>
            下载安卓apk
          </div>
          <div v-else class="btn" @click="copyClick(item.link)">
            <i class="iconfont lebo-fuzhi"></i>
            复制地址
          </div>
          <div class="dowload_btn" @click="downQr(item)">
            <i class="iconfont lebo-xiazai"></i>
            下载二维码
          </div>
        </li>
      </template>
    </ul>
  </lebo-dialog>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import { getPhoneClientLinkAddress } from '@/api/userapi.js'
import VueQr from 'vue-qr'
export default {
  props: {
    PhoneClient: { type: Array }
  },
  components: {
    VueQr
  },
  data () {
    return {
      isPhoneDialog: true,
      phoneClientList: [
        // { name: "商家优免端_H5", value: 1, url: "https://fatcity.lebopark.com/businessManage/login" },
        // { name: "访客登记_H5", value: 2, url: "http://fatcity.lebopark.com/payPages/visitor" },
        // { name: "车主端_H5", value: 3, url: "http://fatcity.lebopark.com/carownerManage/login" },
        // { name: "收费端", value: 4, url: "" },
        // { name: "用户端", value: 5, url: "" },
      ]
      // list: [{
      //   https://fatcity.lebopark.com/businessManage/login
      // }]
    }
  },
  created () {
    this.fnGetPhoneClientLinkAddress()
  },
  mounted () { },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  watch: {},
  methods: {
    async fnGetPhoneClientLinkAddress () {
      const res = await getPhoneClientLinkAddress()
      // console.log('获取手机下载二维码', res)
      this.phoneClientList = res && res.Code === 200 ? res.Data : []
    },
    closeDialog () {
      this.isPhoneDialog = false
      this.$emit('close')
    },
    // 下载二维码
    downQr (item) {
      console.log('下载二维码', item)
      if (item.value !== 6 && item.value !== 7) {
        var a = document.createElement('a')
        console.log(this.$refs[`qrRef${item.value}`][0].$el)
        a.style.display = 'none'
        a.download = item.name
        a.href = this.$refs[`qrRef${item.value}`][0].$el.src
        document.body.appendChild(a)
        a.click()
      } else {
        this.downloadIamge(item.link, item.name)
        // a.style.display = "none";
        // a.download = 'pic';
        // a.href = item.link;
        // document.body.appendChild(a);
        // a.click();
      }
    },
    downloadIamge (imgsrc, name) {
      var image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.onload = function () {
        var canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        var context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        var url = canvas.toDataURL('image/png')
        var a = document.createElement('a')
        var event = new MouseEvent('click')
        a.download = name || 'photo'
        a.href = url
        a.dispatchEvent(event)
      }
      image.src = imgsrc
    },
    // 复制
    copyClick (url) {
      console.log('fuzhi', url)
      const input = document.createElement('input') // 创建input对象
      input.value = url// 设置复制内容
      document.body.appendChild(input) // 添加临时实例
      input.select() // 选择实例内容
      document.execCommand('Copy') // 执行复制
      console.log(input)
      if (document.execCommand('Copy', 'false', null)) {
        // 如果复制成功
        // console.log('点击内容已复制');
        this.$message.success('复制成功')
      }
      // 复制成功之后删除增加的这个input元素
      document.body.removeChild(input)
    },
    // 下载apk
    downApk (item) {
      // this.$message.info('暂未完成')
      var a = document.createElement('a')
      // console.log(this.$refs[`qrRef${item.value}`][0]);
      a.style.display = 'none'
      a.download = item.name
      let downLoadUrl = ''
      if (item.value === 4 || item.value === 5) {
        downLoadUrl = item.download_link
      } else {
        downLoadUrl = item.link
      }
      a.href = downLoadUrl
      document.body.appendChild(a)
      a.click()
    },
    // 设置二维码
    setQrCode (item) {
      if (item.value === 4 || item.value === 5) {
        // "/download-apk/:systemtype/:apktype/:apkurl"
        const linkRoute = item.link
        const apkRoute = item.download_link
        const downloadCode = linkRoute + '?systemtype=3' + '&apktype=' + item.value + '&apkurl=' + apkRoute.split('//')[1]
        return downloadCode
      } else {
        return item.link
      }
    }
  }
}
</script>
<style scoped lang="less">
.phone_dialog {

  /deep/ .el-dialog {
    width: fit-content !important;
  }

  /deep/ .content_box {
    display: flex;
    align-items: center;

    .content_item {
      width: 194px;
      height: 258px;
      background: #ffffff;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      margin-right: 15px;
      padding: 20px 10px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }

      .qr_box {
        width: 90px;
        height: 90px;
        background: #333333;
      }

      .btn {
        width: 154px;
        height: 30px;
        background: rgba(2, 166, 251, 0.1);
        border-radius: 5px;
        line-height: 30px;
        text-align: center;
        font-size: 14px;
        color: #01a6fb;
        cursor: pointer;
      }

      .dowload_btn {
        width: 154px;
        height: 30px;
        background: rgba(245, 82, 101, 0.1);
        border-radius: 5px;
        line-height: 30px;
        text-align: center;
        font-size: 14px;
        color: #f55265;
        cursor: pointer;
      }
    }
  }
}
</style>
