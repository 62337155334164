<template>
  <el-aside class="aside-out-view" :width="!isCollapse?'200px':'64px'">
    <el-menu :default-active="activeMenu" collapse-transition active-text-color="#409EFF" router unique-opened @select="handSelect" :collapse="isCollapse">
      <template v-for="item in leftMenuList">
        <el-submenu :key="item.menu_id" :index="item.menu_url ? item.menu_url : item.menu_id" v-if="item.children && item.children.length > 0 && !item.children[0].is_btn">
          <template slot="title">
            <i :class="'iconfont  lebo-' +  (item.menu_icon.slice(0,4)== 'icon' ?  item.menu_icon.slice(4, item.menu_icon.length) : item.menu_icon)"></i>
            <span slot="title">{{item.show_name}}</span>
          </template>
          <el-menu-item class="el-menu-item_box" v-for="v in item.children" :key="v.menu_id" :index="v.menu_url">
            <i :class="'iconfont  lebo-' +  (v.menu_icon.slice(0,4)== 'icon' ?  v.menu_icon.slice(4, v.menu_icon.length) : v.menu_icon)" :style="activeMenu == v.menu_url ? 'color:#409EFF!important;margin-right:8px;' : 'margin-right:8px;' "></i>
            <span> {{v.show_name}}</span>
          </el-menu-item>
        </el-submenu>
        <el-menu-item :key="item.menu_id" :index="item.menu_url" class="my_el-menu-item" v-else>
          <i :class="'iconfont  lebo-' +  (item.menu_icon.slice(0,4)== 'icon' ?  item.menu_icon.slice(4, item.menu_icon.length) : item.menu_icon)" :style="activeMenu == item.menu_url ? 'color:#409EFF!important;margin-right:8px;' : 'margin-right:8px;' "></i>
          <span slot="title">{{item.show_name}}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </el-aside>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
export default {
  name: 'leftHome',
  props: ['isCollapse'],
  data () {
    return {
    }
  },
  mounted () {
    console.log(this.leftMenuList)
  },
  computed: {
    ...mapGetters(['theme']),
    ...mapState('menuList', ['leftMenuList', 'activeMenu'])
  },
  methods: {
    ...mapMutations('menuList', [
      'setActiveHearderMenu',
      'setActiveMenu',
      'addActiveTabList'
    ]),
    // 菜单切换触发
    handSelect (path) {
      // console.log('菜单切换触发----' + path);
      if (!path) return
      this.setActiveMenu(path)
      // this.addActiveTabList(path);
      //   this.activeIndex = path
      //   this.addTagList(path);
      //   this.setCurrentTag(path);
      //   this.setCurrentBtn(path);
      //   var that = this
      //   setTimeout(() => {
      //     that.getElement()
      //   }, 300)
    }
  }

}
</script>

<style  scoped lang="less">
.el-menu-item_box{
  .iconfont{
    font-size: 12px;
  }
}
</style>
