<template>
  <!-- 首页的头部 -->
  <el-header>
    <div class="left">
      <!-- logo -->
      <div class="logo">
        <img :src="
          // groupInfo.logo_url || require('../../assets/img/home_logo.png')
          groupInfo.logo_url || ''
        " alt="" />
      </div>
      <!-- 切换 -->
      <div class="toggle_box" @click="CheckMenu">
        <i class="iconfont lebo-zhankai" v-if="!isCollapse"></i>
        <i class="iconfont lebo-zhankai02" v-else></i>
      </div>
      <!-- 头部导航 -->
      <template v-for="(item, index) of hearderMenuList">
        <template v-if="!isMenuRoute">
          <a :key="index" class="heard_item" :class="
            activeHearderMenu === item.show_name ? 'current_heard_item' : ''
          " :href="item.menu_url" target="_blank" v-if="item.menu_url && item.menu_url.startsWith('http')"
            rel="noopener noreferrer">
            <i
              :class="'iconfont  lebo-' + (item.menu_icon.slice(0, 4) == 'icon' ? item.menu_icon.slice(4, item.menu_icon.length) : item.menu_icon)"></i>
            {{ item.name }}</a>
          <div :key="item.show_name" class="heard_item" @click.stop.prevent="clickMenuItem(item)"  v-else>
            <span :class="['headName', activeHearderMenu === item.show_name ? 'current_heard_item' : ''
            ]">
              <i
                :class="'iconfont  lebo-' + (item.menu_icon.slice(0, 4) == 'icon' ? item.menu_icon.slice(4, item.menu_icon.length) : item.menu_icon)"></i>
              {{ item.show_name }}
            </span>
              <div>
                <lottie :options="defaultOptions"  class="bottomMove"  v-on:animCreated="handleAnimation"/>
              </div>
            <!-- <template v-if="(theme == 1 || theme == 2) && item && item.children.length > 0 && item.show_name == '大屏'">
              <div class="select_context">
                <span class="select_context_item" v-for="(v, i) of item.children" :key="i"
                  @click.stop.prevent="clickMenuChildItem(v, item.show_name)">{{ v.show_name }}</span>
              </div>
              <div :class="['select_menu_context',theme == 1?'select_menu_context_dark':'select_menu_context_light']" v-if="showChooseScreen">
                <div class="select_menu_close">
                  <div class="el-icon-close" :style="[theme == 1?{color:'#fff'}:{color:'#666'}]" @click.stop.prevent="closeSreenChose"></div>
                </div>
                <div class="select_menu_inner">
                  <div class="select_menu_context_item" v-for="(v, i) of item.children" :key="i"
                  @click.stop.prevent="clickMenuChildItem(v, item.show_name)">
                  <div class="select_menu_context_img">
                    <template v-if="v.screen_pic">
                      <el-image :src="v.screen_pic" fit="cover" ></el-image>
                    </template>
                   <template v-else>
                    <div class="noScreenPic"></div>
                   </template>
                  </div>
                  <div class="select_menu_context_name">{{ v.show_name }}</div>
                </div>
                </div>
              </div>
            </template> -->
            <!-- 菜单样式3 4 -->
            <subMenuFixed :data="item.children" :showName="item.show_name" :key="'children' + index" :flag="false"
              v-if="(theme == 3 || theme == 4) && item && item.children.length > 0 && !item.children[0].is_btn">
            </subMenuFixed>
          </div>
        </template>
        <template v-else>
          <div :key="index" class="heard_item" @click.stop.prevent="clickMenuItem2(item)">
            <span :class="['headName',
              activeHearderMenu === item.title ? 'current_heard_item' : ''
            ]">{{ item.title }}</span>
            <subMenuFixed :data="item.children" :key="'children' + index" :flag="true"
              v-if="(theme == 3 || theme == 4) && item && item.children.length > 0"></subMenuFixed>
          </div>
        </template>
        <!-- <subMenuFixed :data="item.children" :key="'children' + index" :flag="item.show_name == '开发者' || item.show_name == '小助手 '" @menuClick="clickMenuChildItem"></subMenuFixed> -->
      </template>
    </div>
    <div class="right">
      <div class="icon_box" v-if="isBigScreen && !isWorkSpace">
        <i class="iconfont lebo-quanping1" @click="bigfullScreen"></i>
        <i :class="`iconfont ${isShowModule ? 'lebo-hidden' : 'lebo-show'}`" v-if="isBigScreenShowModule"
          @click="setShowModule(!isShowModule)"></i>
      </div>
      <!-- Miracle AI
      <div title="觉悟" class="iconfont lebo-colorfull-juewulogo" @click="goSceneMiracle" v-if="groupInfo.is_laboratory"></div>
      场景配置
      <div title="示例" class="iconfont lebo-shilie" @click="goScene" v-if="groupInfo.is_scene"></div> -->
      <!-- 手机端 -->
      <div title="手机端" class="iconfont lebo-sjd" @click="isPhoneDialog = true"
        v-if="groupInfo.phone_client && groupInfo.phone_client.length > 0"></div>
      <!-- 消息通知 -->
      <div title="消息通知" @click="goMessageNotification">
        <el-badge :value="alarmCount" :max="99" class="item" style="z-index: 999" v-if="alarmCount > 0">
          <i class="iconfont lebo-xiaoxitixing-wuicon"></i>
        </el-badge>
        <i class="iconfont lebo-xiaoxitixing-wuicon" v-else></i>
      </div>
      <!-- 切换平台 -->
      <el-dropdown trigger="click" @command="showMoreHandelCommand" placement="bottom"
        v-if="dropdownMenuList.length > 0">
        <span class="el-dropdown-link">
          <i class="iconfont lebo-gengduogongnengicon" style=" margin-left: 0;"></i>
        </span>
        <el-dropdown-menu slot="dropdown" class="showMore_box" :style="'width: 202px;'">
          <el-dropdown-item :icon="'iconfont ' + item.icon" v-for="(item, index) in dropdownMenuList" :key="index"
            :command="item.name" >
            <div>
            {{ item.name }}
            </div>
          </el-dropdown-item>
          <!-- <el-dropdown-item :icon="'iconfont lebo-gongsi'" command="公司官网" v-if="isAdmin || ">公司官网</el-dropdown-item>
          <el-dropdown-item :icon="'iconfont lebo-ruanjiankaifabao'" command="开发者">开发者</el-dropdown-item>
          <el-dropdown-item :icon="'iconfont lebo-caozuoshouce'" command="小助手">小助手</el-dropdown-item> -->
        </el-dropdown-menu>
      </el-dropdown>
      <!-- 用户信息 -->
      <el-dropdown trigger="click" @command="userInfoHandleCommand">
        <span class="el-dropdown-link">
          {{ getUserInfo.user_name }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item style="padding: 0 10px" icon="iconfont lebo-class"
            command="userInfo">基本信息</el-dropdown-item>
          <el-dropdown-item style="padding: 0 10px" icon="iconfont lebo-qiehuanzuhu"
            command="loginOut">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 手机端 -->
    <phoneDialog v-if="isPhoneDialog" @close="isPhoneDialog = false" :PhoneClient="groupInfo.phone_client">
    </phoneDialog>
  </el-header>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import { getMaintenanceMain } from '@/api/maintenanceCenter'
import subMenuFixed from '../subMenuFixed/index.vue'
import phoneDialog from '../phoneDialog/index.vue'
import * as animationData from '../../../../assets/img/lottie/tableHover.json'
export default {
  name: 'topHome',
  components: {
    subMenuFixed,
    phoneDialog
  },
  data () {
    return {
      isCollapse: false,
      isSubMenuFixed: false,
      list: [],
      dropdownMenuList: [],
      isPhoneDialog: false,
      defaultOptions: { animationData: animationData.default, loop: true },
      anim: '',
      showChooseScreen: false
    }
  },
  computed: {
    ...mapGetters(['getUserInfo', 'isAdmin', 'getOptions', 'getWayName']),
    ...mapGetters('menuList', ['theme', 'shortcutList', 'groupInfo']),
    ...mapState('menuList', [
      'activeIndex',
      'groupInfo',
      'hearderMenuList',
      'activeMenu',
      'activeHearderMenu',
      'allMenuList',
      'isShowMsg',
      'isBigScreen',
      'isBigScreenShowModule',
      'leftMenuList',
      'isMenuRoute',
      'isWorkSpace',
      'activeTabList'
    ]),
    ...mapState(['alarmCount', 'projectInfo', 'isShowModule'])
  },
  mounted () {
    var that = this
    that.$store.dispatch('requestAlarmCount')
    setInterval(() => {
      that.$store.dispatch('requestAlarmCount')
    }, 1000 * 60)
    setTimeout(() => {
      that.initList()
      // console.log('mounted----', that.shortcutList);
    }, 500)
    this.setIsWorkSpace(false)

    // console.log('hearderMenuList', this.hearderMenuList)
  },
  methods: {
    ...mapMutations(['changeScreenStatus', 'setShowModule']),
    ...mapMutations('menuList', [
      'setLeftMenuList',
      'setActiveHearderMenu',
      'setActiveMenu',
      'setMenuList',
      'addTab',
      'setDevAndHelpList',
      'setThree3DUrl',
      'setIsBigScreen',
      'setIsMenuRoute',
      'setActiveIndex',
      'setIsWorkSpace',
      'closeTab',
      'closeAllTab'
    ]),
    ...mapMutations(['log_out']),
    styleIcon (val) {
      switch (val) {
        case '公司官网':
          return 'lebo-guanwang'
        case '开发者':
          return 'lebo-devlopment'
        case '小助手':
          return 'lebo-xiaozhushou'
        case '工作台':
          return 'lebo-a-gongzuotai'
        case '觉悟':
          return 'lebo-colorfull-juewulogo'
        case '示例':
          return 'lebo-shilie'
      }
    },
    initList () {
      if (this.shortcutList.length <= 0) return []
      var newArr = []
      // this.shortcutList.forEach(item => {
      //   newArr.unshift({
      //     name: this.getWayName('GroupShortCut', item),
      //     icon: this.styleIcon(this.getWayName('GroupShortCut', item)),
      //     value: item
      //   })
      // })
      this.getOptions('GroupShortCut').forEach((item) => {
        if (
          this.shortcutList &&
          this.shortcutList.length > 0 &&
          this.shortcutList.includes(item.value)
        ) {
          newArr.push({
            name: item.name,
            icon: this.styleIcon(item.name),
            value: item.value
          })
        }
      })
      newArr.unshift({
        name: '云平台',
        value: 0,
        icon: 'lebo-a-yunpingtai'
      })

      this.dropdownMenuList = newArr
      // console.log('this.shortcutList---', this.shortcutList);
      console.log('this.dropdownMenuList----', this.dropdownMenuList)
    },
    // 点击退出登录或基本信息事件
    userInfoHandleCommand (command) {
      if (command === 'userInfo') {
        // 展示用户基本信息
        this.$emit('openUserInfoDialo')
      } else if (command === 'loginOut') {
        sessionStorage.clear()
        localStorage.clear()
        // 退出登录
        this.loginOut()
      }
    },
    // 退出登录
    loginOut () {
      sessionStorage.clear()
      this.$store.commit('log_out')
      localStorage.clear()
      this.$router.push('/login')
      this.$emit('log-out')
    },
    // 点击展示更多事件
    showMoreHandelCommand (command) {
      console.log('点击展示更多-----', command)
      if (command === '云平台') {
        this.setMenuList(command)
        this.$emit('changeMenu', false)
        this.setIsMenuRoute(false)
        this.setIsWorkSpace(false)
      } else if (command === '公司官网') {
        this.fnGetMaintenanceMain(6)
      } else if (command === '开发者') {
        this.fnGetMaintenanceMain(1)
        this.setIsMenuRoute(true)
      } else if (command === '小助手') {
        this.fnGetMaintenanceMain(2)
        this.setIsMenuRoute(true)
      } else if (command === '工作台') {
        console.log('远程呼叫跳转')
        this.setMenuList(command)
        this.$emit('changeMenu', false)
        this.setIsMenuRoute(false)
        this.setIsWorkSpace(true)
        // 1、添加头部路由
        // 2、this.setIsMenuRoute(false)
        // 3、页面跳转
        // this.fnGetMaintenanceMain(99)
        // this.setIsMenuRoute(true)
      } else if (command === '觉悟') {
        this.setMenuList(command)
        this.$emit('changeMenu', false)
        this.setIsMenuRoute(false)
        this.setIsWorkSpace(true)
      } else if (command === '示例') {
        this.setMenuList(command)
        this.$emit('changeMenu', false)
        this.setIsMenuRoute(false)
        this.setIsWorkSpace(true)
      }
    },
    async fnGetMaintenanceMain (type) {
      const res = await getMaintenanceMain({
        type: type
      })
      console.log(res)
      if (type === 99) {
        this.setDevAndHelpList({
          list: [{
            bypartitle: '接入指南',
            children: [],
            title: '接入指南'
          }],
          type: 99
        })
        this.$emit('changeMenu', true)
      }
      if (res.Code === 200) {
        if (res.Data && res.Data.length > 0) {
          if (type === 6) {
            // 公司官网
            window.open(res.Data[0].content)
          } else if (type === 1) {
            this.setDevAndHelpList({
              list: res.Data,
              type: 1
            })
            this.$emit('changeMenu', true)
          } else if (type === 2) {
            this.setDevAndHelpList({
              list: res.Data,
              type: 2
            })
            this.$emit('changeMenu', true)
          }
        } else {
          this.$msg.info('未配置数据，请联系管理员配置数据！')
        }
      }
    },
    // 切换左侧导航
    CheckMenu () {
      this.isCollapse = !this.isCollapse
      this.$emit('checkLeftMenu')
    },
    // 跳转到消息通知页面
    goMessageNotification () {
      console.log('/message-notification')
      this.setActiveIndex('')
      this.setLeftMenuList([])
      this.addTab({
        isBigScreen: false,
        key: '/message-notification',
        value: '消息通知'
      })
      this.setActiveMenu('/message-notification')
    },
    // 跳转到 Miracle chat
    goSceneMiracle () {
      this.setActiveIndex('')
      this.setLeftMenuList([])
      // this.addTab({
      //   isBigScreen: false,
      //   key: '/miracle-chat',
      //   value: 'Miracle chat'
      // })
      this.setActiveMenu('/miracle-chat')
    },
    // 跳转到场景页面
    goScene () {
      // console.log('/message-notification');
      this.setActiveIndex('')
      this.setLeftMenuList([])
      // this.addTab({
      //   isBigScreen: false,
      //   key: '/scene-configuration',
      //   value: '场景'
      // })
      this.setActiveMenu('/scene-configuration')
    },
    // 跳到大屏选择
    goScreenSelect (item) {
      this.setActiveIndex('')
      this.setLeftMenuList([])
      this.setActiveMenu('/screen-selection')
      this.setActiveHearderMenu('大屏')
    },
    // 点击头部导航下的子菜单触发
    clickMenuChildItem (item, name) {
      console.log('==--==item--==clickMenuChildItem=')
      this.changeMenu(item, name)
      this.showChooseScreen = false
    },
    // 点击头部导航菜单触发
    clickMenuItem (item) {
      if (item.show_name === '大屏') {
        console.log('item', item)
        if (item.children.length === 1) {
          this.setIsBigScreen(item.children[0].menu_url)
          this.setLeftMenuList([])
          this.setActiveMenu(item.children[0].menu_url)
        } else {
          this.goScreenSelect(item)
        }
        // this.showChooseScreen = true
      } else {
        this.changeMenu(item, item.show_name)
      }
    },
    changeMenu (item, name) {
      console.log('changeMenu-------', item)
      this.showChooseScreen = false
      this.setShowModule(true) // 跳转重绘隐藏小组件功能
      // 点击 导航栏头部 如果导航栏没有子级那么就直接跳转
      // if (item.menu_url === "") {
      //   return;
      // }
      if (item.menu_url.startsWith('http')) {
        this.setThree3DUrl(item.menu_url)
        // window.open(item.menu_url);
        return
      }
      this.setThree3DUrl(null)
      this.setActiveHearderMenu(name)
      if (item.children && item.children.length > 0) {
        // 设置左侧菜单
        // console.log('changeMenu-------', item);
        var str = this.getFirstUrl(item.children, 0)
        // console.log(str);
        this.setLeftMenuList(item.children)
        this.setActiveMenu(item.menu_url || str)
        this.setIsBigScreen(item.menu_url)
      } else {
        // 设置左侧菜单
        this.setLeftMenuList([])
        this.setActiveMenu(item.menu_url)
      }
    },
    clickMenuItem2 (item) {
      console.log('点击了跳转', item)
      this.setActiveMenu('')
      this.setActiveHearderMenu(item.title)
      // 设置左侧菜单
      this.setLeftMenuList(item.children)
      this.setActiveIndex(item.id)
    },
    // 获取第一个子元素的url
    getFirstUrl (data, index) {
      var str = ''
      function findFirstNode (data) {
        if (data[index]) {
          if (data[index].menu_url) {
            str = data[index].menu_url
          } else {
            findFirstNode(data[index].children)
          }
        }
      }
      findFirstNode(data)
      return str
    },
    // 全屏
    bigfullScreen () {
      this.changeScreenStatus(true)
      var element = document.querySelector('body')
      // if (de.requestFullscreen) {
      //   de.requestFullscreen();
      // } else if (de.mozRequestFullScreen) {
      //   de.mozRequestFullScreen();
      // } else if (de.webkitRequestFullScreen) {
      //   de.webkitRequestFullScreen();
      // }
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen()
      } else if (element.oRequestFullscreen) {
        element.oRequestFullscreen()
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullScreen()
      } else {
        var docHtml = document.documentElement
        var docBody = document.body
        var videobox = document.getElementById('monitors')
        var cssText = 'width:100%;height:100%;overflow:hidden;'
        docHtml.style.cssText = cssText
        docBody.style.cssText = cssText
        videobox.style.cssText = cssText + ';' + 'margin:0px;padding:0px;'
        document.IsFullScreen = true
      }
    },
    // lottie动画
    handleAnimation (anim) {
      this.anim = anim
    },
    closeSreenChose () {
      this.showChooseScreen = false
    }
  }
}
</script>

<style scoped lang="less">
.showMore_box {
  width: 300px !important;
// height: 240px;
background: #FFFFFF;
box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.15);
border-radius: 5px 5px 5px 5px;
border: 1px solid #01A6FB;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  box-sizing: border-box;

  .el-dropdown-menu__item {
    background: #FFFFFF;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #E6E6E6;
    margin: 5px;
    width: 82px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 24px;
    /deep/ .iconfont {
      font-size:22px !important;
      margin: 0!important;
      // margin-bottom: 5px;
    }
  }
}
.logo{
  width: 10%;
  height:80%!important;
  // background-color: #fff;
  position: relative;
  img{
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    display: block;
  }
}
</style>
