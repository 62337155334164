//  编写修改主题的方法
import { themes } from './model'
// 修改页面中的样式变量值
const changeStyle = (obj) => {
  for (var key in obj) {
    document
      .getElementsByTagName('body')[0]
      .style.setProperty(`--${key}`, obj[key])
  }
}
// 改变主题的方法
export const setTheme = (themeName) => {
  localStorage.setItem('theme', themeName) // 保存主题到本地，下次进入使用该主题
  const themeConfig = themes[themeName]
  // 如果有主题名称，那么则采用我们定义的主题
  if (themeConfig) {
    localStorage.setItem('topBgColor', themeConfig.topBgColor) // 保存主题色到本地
    localStorage.setItem('homeTextColor', themeConfig.homeTextColor) // 保存文字颜色到本地
    changeStyle(themeConfig) // 改变样式
  } else {
    var themeConfig1 = {
      topBgColor: localStorage.getItem('topBgColor'),
      homeTextColor: localStorage.getItem('homeTextColor')
    }
    changeStyle(themeConfig1)
  }
}
