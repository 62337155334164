// 一套默认主题以及一套暗黑主题
export const themes = {
  default: {
    topBgColor: '#1a232c',
    homeTextColor: '#ffffff',
    contentBgColor: '#F2F2F2',
    writeColor: '#ffffff',
    currentColor: '#01a6fb',
    mainColor: '#666666',
    unselectTitleColor: '#999999',
    unselectMenuColor: '#b2b2b2',
    tabsTitleBgColor: '#fafafa'
  },
  light: {
    topBgColor: '#fff',
    homeTextColor: '#000',
    contentBgColor: '#F2F2F2',
    writeColor: '#ffffff',
    currentColor: '#01a6fb',
    mainColor: '#666666',
    unselectTitleColor: '#999999',
    unselectMenuColor: '#b2b2b2',
    tabsTitleBgColor: '#fafafa'
  }
}
