<template>
  <!-- 首次登录设置密码 -->
  <lebo-dialog  append-to-body title="设置密码" :isShow="$store.state.firstLogin" width="30%" center @close="closeDialog()" footerSlot>
    <el-form :model="setPsdRuleForm" status-icon :rules="setPsdRuleFormRules" style="padding: 0 30px;" ref="setPsdRuleFormRuleForm" label-width="80px" class="demo-ruleForm">
      <el-form-item label="新密码" prop="password">
        <el-input :type="isPassWord ? 'text':'password'" v-model="setPsdRuleForm.password" autocomplete="off" @input="(e) => (setPsdRuleForm.password = validSpacing(e))">
          <i slot="suffix" style="cursor: pointer;" @click="isPassWord = !isPassWord;" :class="'iconfont '+ (isPassWord ? 'iconzhengyanicon':'iconbiyan')"></i>
        </el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPassword">
        <el-input :type="isNewPassWord ? 'text':'password'" v-model="setPsdRuleForm.checkPassword" autocomplete="off" @input="(e) => (setPsdRuleForm.checkPassword = validSpacing(e))">
          <i slot="suffix" style="cursor: pointer;" @click="isNewPassWord = !isNewPassWord;" :class="'iconfont '+ (isNewPassWord ? 'iconzhengyanicon':'iconbiyan')"></i>
        </el-input>
      </el-form-item>
      <el-form-item>注：8~14个字符，包含字母、数字，区分大小写</el-form-item>
      <el-form-item class="btn_box">
        <el-button @click="resetPassword()" type="primary">确 定</el-button>
      </el-form-item>
    </el-form>
  </lebo-dialog>
</template>

<script>
import { updateUserFirstLogin } from '@/api/userapi.js'
import requst from '@/api/systemapi'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'firstLoginDialog',
  data () {
    // 自定义验证规则  用户密码
    var psdRule = (rule, value, callback) => {
      const psdReg = /^[\w]{8,14}$/
      if (psdReg.test(value)) {
        return callback()
      }
      callback(new Error())
    }
    // 确定密码
    var checkPsdRule = (rule, value, callback) => {
      if (value === this.setPsdRuleForm.password) {
        return callback()
      } else {
        callback(new Error())
      }
    }
    return {
      // 设置密码
      setPsdRuleForm: {
        password: '',
        checkPassword: ''
      },
      // 设置密码验证规则
      setPsdRuleFormRules: {
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          {
            validator: psdRule,
            message: '8-14个字符，包含字母、数字，区分大小写',
            trigger: 'blur'
          }
        ],
        checkPassword: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          {
            validator: checkPsdRule,
            message: '两次输入密码不一致，请重新输入!',
            trigger: 'blur'
          }
        ]
      },
      // 是否显示密码
      isPassWord: false,
      isNewPassWord: false
    }
  },
  computed: {
    ...mapGetters(['getUserInfo'])
  },
  methods: {
    ...mapMutations([
      'setFirstLogin'
    ]),
    // 当重设密码对话框关闭时，清空输入框
    closeDialog () {
      this.$refs.setPsdRuleFormRuleForm.resetFields()
      this.fnUpdateUserFirstLogin()
    },
    // 重设密码
    async resetPassword () {
      // 对整个表单进行校验
      this.$refs.setPsdRuleFormRuleForm.validate(async (valid) => {
        // 成功
        if (valid) {
          // console.log("重设密码");
          const res = await requst.resetPwd({
            _id: this.getUserInfo.user_id,
            password: this.setPsdRuleForm.checkPassword
          })
          // console.log(res);
          if (res && res.Code === 200) {
            this.$msg.success(res.Message)
            const newVaule = this.deepClone(this.getUserInfo)
            newVaule.first_login = false
            this.$store.commit('setUserInfo', newVaule)
            this.$store.commit('setFirstLogin', false)
            // this.fnUpdateUserFirstLogin()
          }
        } else {
          return false
        }
      })
    },
    // 修改用户首次登陆状态
    async fnUpdateUserFirstLogin () {
      const res = await updateUserFirstLogin({
        user_id: this.getUserInfo.user_id
      })
      // console.log(res);
      if (res && res.Code === 200) {
        // this.$message(res.Message)
        setTimeout(() => {
          const newVaule = this.deepClone(this.getUserInfo)
          newVaule.first_login = false
          this.$store.commit('setUserInfo', newVaule)
          this.$store.commit('setFirstLogin', false)
        }, 300)
      }
    }
  }

}
</script>

<style  scoped lang="less">
.btn_box {
  /deep/ .el-form-item__content {
    text-align: center;
    margin-left: 0px !important;
  }
}
</style>
