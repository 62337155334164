<template>
  <div v-if="showName !== '大屏'">
    <div :class="theme === 3 ? 'sub_menu_fixed_dark' : 'sub_menu_fixed'">
    <!-- <div class="sub_menu_fixed_dark"> -->
    <template v-if="flag">
      <ul class="two_menu_box">
        <li class="two_menu_item" v-for="(item, index) in data" :key="index">
          <span :class="item.content ? 'two_title' : 'folder_title'">{{ item.title }}</span>
          <ul class="menu_box" v-if="item.children && item.children.length > 0">
            <li class="menu_item" v-for="(t, i) in item.children" :key="i" @click.stop.prevent="handSelect2(t.id)">
              <span :class="'title'">{{ t.title }}</span>
            </li>
          </ul>
        </li>
      </ul>
      <!-- <ul class="one_menu_box">
        <li class="one_menu_item" v-for="(item,index) in data" :key="index" @mousemove.stop.prevent="mouseMoveHandle(index, item)">
          <span :class="['one_title', currentIndex == index ? 'one_title_current' : '']" v-if="item.children.length > 0">{{item.title}}</span>
          <span :class="['title', item.id == activeIndex ? 'current':'']" v-else @click="handSelect2(item.id)">{{item.title}}</span>
        </li>
      </ul>
      <el-divider direction="vertical"></el-divider>
      <ul class="two_menu_box" v-if="data[currentIndex].children && data[currentIndex].children.length > 0">
        <li class="two_menu_item" v-for="(k,j) in data[currentIndex].children" :key="j">
          <div class="folder_title" v-if="k.children.length > 0">{{k.title}}</div>
          <span :class="['title', k.id == activeIndex ? 'current':'']" v-else @click="handSelect2(k.id)">{{k.title}}</span>
          <ul class="menu_box" v-if="k.children.length > 0 && !k.children[0].is_btn">
            <li class="menu_item" v-for="(t,i) in  k.children" :key="i" @click="handSelect2(t.id)">
              <span :class="['title', t.id == activeIndex ? 'current':'']">{{t.title}}</span>
            </li>
          </ul>
        </li>
      </ul>-->
    </template>
    <template v-else>
      <!-- <template v-if="showName == '大屏'">
        <div class="screenBigBox">
          <div class="screenBoxItem" v-for="(item, index) in data" :key="index"
            @click.stop.prevent="handSelect(item.menu_url)">
            <div class="screenInnerPic" v-if="item.screen_pic">
              <el-image :src="item.screen_pic" fit="cover"></el-image>
            </div>
            <div class="screenInnerNoPic" v-else>

            </div>
            <div class="screenInnerName">
              {{ item.show_name }}
            </div>
          </div>
        </div>
      </template> -->
      <ul class="two_menu_box">
        <li class="two_menu_item" v-for="(item, index) in data" :key="index">
          <span :class="[item.is_folder ? 'folder_title' : 'two_title']" @click.stop.prevent="handSelect(item.menu_url)">
            <i
              :class="'iconfont  lebo-' + (item.menu_icon.slice(0, 4) == 'icon' ? item.menu_icon.slice(4, item.menu_icon.length) : item.menu_icon)"></i>
            <span>{{ item.show_name }}</span>
          </span>
          <ul class="menu_box" v-if="item.children && item.children.length > 0 && !item.children[0].is_btn">
            <li class="title" v-for="(t, i) in item.children" :key="i" @click.stop.prevent="handSelect(t.menu_url)">
              <!-- <span :class="'title'"> -->
              <i
                :class="'iconfont  lebo-' + (t.menu_icon.slice(0, 4) == 'icon' ? t.menu_icon.slice(4, t.menu_icon.length) : t.menu_icon)"></i>
              <span>{{ t.show_name }}</span>
            </li>
          </ul>
        </li>
      </ul>
    </template>
  </div>
  </div>

</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
export default {
  props: {
    data: { type: Array },
    flag: { type: Boolean },
    showName: { type: String }
  },
  data () {
    return {}
  },
  created () { },
  mounted () {
    console.log('下拉菜单', this.data, this.showName)
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName']),
    ...mapGetters('menuList', ['theme']),
    ...mapState('menuList', ['leftMenuList', 'activeMenu', 'activeIndex'])
  },
  watch: {},
  methods: {
    ...mapMutations('menuList', [
      'setLeftMenuList',
      'setActiveHearderMenu',
      'setActiveMenu',
      'setActiveIndex',
      'setMenuList',
      'addTab',
      'setDevAndHelpList',
      'setThree3DUrl',
      'setIsBigScreen'
    ]),
    // clickMenu(item) {
    //   if (!item.menu_url) return;
    //   this.setActiveMenu(path);
    // },
    // // // 点击左侧二级触发
    // // oneClick(item) {
    // //   this.$emit('oneClick', item);
    // // },
    // // twoClick(item) {
    // //   this.$emit('twoClick', item);
    // // },
    handSelect (path) {
      console.log('handSelect------', path)
      if (!path) return
      this.setIsBigScreen(path)
      this.setLeftMenuList([])
      this.setActiveMenu(path)
    },
    // 菜单切换触发(开发者、小助手)
    handSelect2 (path) {
      this.setActiveIndex(path)
      this.setActiveMenu('')
    }
  }
}
</script>
<style scoped lang="less">
.sub_menu_fixed_dark {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 9999;
  display: none;
  align-items: flex-start;
  justify-content: flex-start;
  // padding: 10px 30px 10px 250px;
  background-image: url("~@/assets/images/toHeardBgdark.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 300px;

  .title {
    color: #fff !important;

    .iconfont {
      color: #fff !important;
    }
  }

  .two_title {
    color: #fff !important;

    .iconfont {
      color: #fff !important;
    }
  }
}

.sub_menu_fixed {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 9999;
  display: none;
  min-height: 300px;
  align-items: flex-start;
  justify-content: flex-start;
  // padding: 10px 30px 10px 250px;
  background-image: url("~@/assets/images/toHeardBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

// /deep/ .one_menu_box {
//   display: flex;
//   flex-direction: column;
//   padding: 0 20px;
//   border-right: 1px solid #000;
//   .one_menu_item {
//     display: flex;
//     box-sizing: border-box;
//     height: 100%;
//     .one_title {
//       font-size: 16px;
//       font-weight: 700;
//       color: #1a1a1a;
//       line-height: 40px;
//       height: 40px;
//       padding: 0 10px;
//       display: block;
//       // &:hover {
//       //   color: @currentColor;
//       //   border-bottom: 1px solid @currentColor;
//       // }
//     }
//     .one_title_current {
//       color: @currentColor;
//       border-bottom: 1px solid @currentColor;
//     }
//     // &:hover {
//     //   .two_menu_box {
//     //     display: flex;
//     //   }
//     // }
//   }
// }
.screenBigBox {
  display: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 800px;
  padding: 30px;
  margin-bottom: 30px;
  box-sizing: border-box;
  overflow-y: auto;

  .screenBoxItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 30%;
    height: 45%;
    margin: 1%;

    &:hover {
      .screenInnerName {
        background-image: url('~@/assets/images/screenBackNameHover.png');
      }

      .screenInnerPic {
        border-color: #E39F28;
      }

      .screenInnerNoPic {
        border-color: #E39F28;

      }
    }

    .screenInnerNoPic {
      width: 100%;
      height: 100%;
      background-image: url('~@/assets/img/noMessage/noScreen.png');
      background-size: 30% 50%;
      background-position: center center;
      background-repeat: no-repeat;
      border: 1px solid#01A6FB;
    }

    .screenInnerPic {
      width: 100%;
      height: 85%;
      border: 1px solid#01A6FB;
      box-sizing: border-box;

      .el-image {
        width: 100%;
        height: 100%;

        .el-image__error {
          background-image: url('~@/assets/img/noMessage/failloadpic.png');
          background-size: 30% 60%;
          background-position: center center;
          background-repeat: no-repeat;
          background-color: transparent;
          font-size: 0px;
        }
      }
    }

    .screenInnerName {
      margin-top: 8px;
      width: 100%;
      height: 45px;
      text-align: center;
      line-height: 45px;
      font-size: 20px;
      color: #fff;
      background-image: url('~@/assets/images/screenBackNameNormal.png');
      background-size: 100% 100%;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
}

/deep/ .two_menu_box {
  display: flex;
  justify-content: flex-start;
  align-items: start;
  flex-wrap: wrap;
  padding: 10px 30px 10px 250px;

  .two_menu_item {
    min-width: 150px;
    padding: 0 10px;

    .folder_title {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 14px !important;
      color: #01a6fb !important;
      text-align: left;
      height: 30px;
      line-height: 30px;
      cursor: default;

      .iconfont {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 15px;
        font-size: 14px !important;
        color: #01a6fb !important;
        margin-right: 5px;
      }
    }

    .two_title {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 14px !important;
      color: #4d4d4d;
      text-align: left;
      height: 30px;
      line-height: 30px;
      cursor: pointer;

      .iconfont {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 15px;
        font-size: 14px !important;
        color: #4d4d4d;
        margin-right: 5px;
      }

      &:hover {
        color: #01a6fb;

        .iconfont {
          color: #01a6fb;
        }

        // background-color: rgba(#01a6fb, 0.1);
      }
    }
  }
}

/deep/ .menu_box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  font-size: 14px;

  .title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    font-size: 14px !important;
    color: #4d4d4d;
    text-align: left;

    .iconfont {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15px;
      height: 15px;
      font-size: 14px !important;
      color: #4d4d4d;
      margin-right: 5px;
    }

    &:hover {
      color: #01a6fb;

      .iconfont {
        color: #01a6fb;
      }

      // background-color: rgba(#01a6fb, 0.1);
    }
  }
}

// /deep/ .sub_menu_box {
//   display: flex;
//   flex-direction: column;
//   padding: 0 20px;
//   border-right: 1px solid #000;
//   .sub_menu_item {
//     height: 50px;
//     font-size: 16px;
//     color: #1a1a1a;
//     line-height: 50px;
//   }
//   .current {
//     color: @currentColor;
//   }
// }
// /deep/ .three_menu_box {
//   display: flex;
//   justify-content: flex-start;
//   align-items: start;
//   .three_menu_item {
//     min-width: 150px;
//     padding: 10px;

//     .sub_title {
//       display: inline-block;
//       width: 100%;
//       font-size: 16px;
//       color: @currentColor;
//       text-align: left;
//     }
//     .title {
//       display: inline-block;
//       width: 100%;
//       font-size: 14px;
//       color: #4d4d4d;
//       text-align: left;
//       &:hover {
//         background-color: rgba(#01a6fb, 0.1);
//       }
//     }
//     .four_menu_box {
//       display: flex;
//       flex-direction: column;
//       justify-content: flex-start;
//       align-items: start;
//       .four_menu_item {
//         width: 100%;
//         height: 45px;
//         line-height: 45px;
//       }
//     }
//   }
// }
.el-icon-close {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 18px;
  font-weight: 700;
  color: #000;
}</style>
