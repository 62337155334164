<template>
  <div class="tabs_box" id="tabsBox">
    <ul class="tabs_title_box">
      <li class="tabs_title" :class="activeMenu == item ? 'is_active' : ''" v-for="(item, index) in tabsList"
        :key="index" @click="setActiveMenu(item)">
        <span>{{ getTabName(item) }}</span>
        <i class="el-icon-close" @click.stop.prevent="closeTab(item)"></i>
      </li>
      <li class="tabs_toggle" id="tabsToggle" v-if="hideTabsList.length > 0" @click="isToggleShow = !isToggleShow">
        <i class="iconfont lebo-liebiao"></i>
      </li>
    </ul>
    <ul class="tabs_title_hide_box" id="rightBox" v-if="isToggleShow && hideTabsList.length > 0">
      <li class="tabs_title_hide" v-for="(item, index) in hideTabsList" :key="index" @click="clickRightItem(item)">
        <span>{{ getTabName(item) }}</span>
        <i class="el-icon-close" @click.stop.prevent="closeRightTab(item)"></i>
      </li>
    </ul>
    <div class="tabs_content">
      <router-view />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
export default {
  name: 'homeTabs',
  props: {},
  data () {
    return {
      // tabsList: [],
      // hideTabsList: [],
      isToggleShow: false
    }
  },
  created () { },
  mounted () {
    // this.fnKeydown();
    var that = this
    document.addEventListener('click', (e) => {
      var box = document.getElementById('tabsToggle')
      if (box) {
        if (!box.contains(e.target)) {
          that.isToggleShow = false
        }
      } else {
        that.isToggleShow = false
      }
    })
    this.computeTabs()
    window.onresize = () => {
      return (() => {
        this.isToggleShow = false
        this.computeTabs()
      })()
    }
  },
  computed: {
    tabsList: function () {
      return this.activeTabList.concat().slice(0, this.splitIndex)
    },
    hideTabsList: function () {
      return this.activeTabList
        .concat()
        .slice(this.splitIndex, this.activeTabList.length - 1)
    },
    ...mapGetters('menuList', ['getTabName']),
    ...mapState('menuList', ['activeMenu', 'splitIndex', 'activeTabList'])
  },
  watch: {},
  methods: {
    ...mapMutations('menuList', ['setActiveMenu', 'closeTab', 'setSplitIndex']),
    // 计算需要展示和隐藏的tabs
    computeTabs () {
      this.$nextTick(() => {
        // 1、获取整个tab组件的宽度
        var tabsBox = document.getElementById('tabsBox')
        var width = tabsBox && tabsBox.getBoundingClientRect().width
        // 2、获取单个tab标题的宽度
        // var tabsTitleBox = document.getElementsByClassName('tabs_title');
        // console.log(tabsTitleBox);
        // var tabWidth = tabsTitleBox[0].getBoundingClientRect().width;
        // 3、计算可以放下几个tab
        var index = Math.floor((width - 44) / 170)
        this.setSplitIndex(index)
      })
    },
    clickRightItem (item) {
      window.removeEventListener('click', () => {
        console.log('clickRightItem----remove')
        // doSth()?
      })
      console.log('clickRightItem-----')
      this.setActiveMenu(item)
      this.isToggleShow = false
    },
    closeRightTab (item) {
      this.closeTab(item)
      this.computeTabs()
    }
  }
}
</script>
<style lang="less">
@import url("~@/assets/css/color.less");

.tabs_box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;

  .tabs_title_box {
    width: 100%;
    display: flex;
    text-align: left;
    cursor: pointer;
    z-index: 999;

    .tabs_title {
      width: 170px;
      height: 35px;
      background-color: @tabsTitleBgColor;
      border-radius: 10px 10px 0px 0px;
      margin-right: 10px;
      font-size: 14px;
      color: @unselectTitleColor;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      box-sizing: border-box;

      span {
        font-weight: 700;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical
      }

      &:hover {
        background-color: #f5f5f5;
      }
    }

    .is_active {
      background-color: #fff;
      color: @currentColor;
    }

    .tabs_toggle {
      position: absolute;
      top: 0;
      right: 0;
      width: 35px;
      height: 35px;
      background-color: @tabsTitleBgColor;
      border-radius: 10px 10px 0px 0px;
      color: @unselectTitleColor;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;

      .iconfont {
        font-size: 18px;
      }
    }
  }

  .tabs_title_hide_box {
    width: 200px;
    max-height: 600px;
    overflow-y: auto;
    background-color: @writeColor;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    padding: 10px;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    top: 40px;
    z-index: 9999;

    .tabs_title_hide {
      width: 100%;
      padding: 7px 10px;
      box-sizing: border-box;
      text-align: left;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }

  .tabs_content {
    flex: 1;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    background-color: @writeColor;
    padding: 10px;
    padding-top: 0px;
    box-shadow: 0px 0px 10px 0px rgba(1, 166, 251, 0.2);
    box-sizing: border-box;
  }
}
</style>
